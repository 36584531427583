/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-empty */
import { parseCookies, setCookie } from 'nookies';

export class CookieService {
  static context = null;

  static setContext(newContext) {
    this.context = newContext;
  }

  static get(name) {
    const isServer = typeof window === 'undefined';

    if (!isServer) {
      // ======================= From Client Side =======================
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return (
          parts
            .pop()
            .split(';')
            .shift() || ''
        );
      }
    } else {
      // ======================= From Server Side =======================
      const cookies = parseCookies(this.context);
      return cookies[name] || '';
    }
  }

  static parseCookies() {
    return parseCookies(this.context);
  }

  static set(name, value, options = {}) {
    try {
      const ONE_DAY = 1 * 24 * 60 * 60;

      options = {
        maxAge: ONE_DAY,
        path: '/',
        // sameSite: 'Strict',
        ...options,
      };

      setCookie(this.context, name, value, options);
    } catch (error) {}
  }

  static remove(name) {
    try {
      this.set(name, '');
    } catch (error) {}
  }
}
