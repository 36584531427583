import React from 'react';
import { useSelector } from 'react-redux';
// import { useAuth } from 'src/contexts/auth';
import { Redirect, Route } from 'react-router-dom';

function PrivateRoute(props) {
  const auth = useSelector(state => state.auth);
  const { authed } = auth;

  return authed === true ? <Route {...props} /> : <Redirect to="/login" replace />;
}

export default PrivateRoute;
