import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  parent_name: '',
  parent_email: '',
  parent_phone: '',
  address: '',
  child_name: '',
  child_birthday: '',
  search: '',
};
export const registrationItemSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setItem: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setDefaultValue: (_, action) => {
      if (!action?.payload?.id) return initialState;
      return { ...action.payload };
    },
    resetData: () => {
      return initialState;
    },
  },
});
export const { setItem, setDefaultValue, resetData } = registrationItemSlice.actions;

export default registrationItemSlice.reducer;
