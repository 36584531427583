import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  code: '',
  full_name: '',
  block_id: '',
  position: '',
  email: '',
  phone_number: '',
  introduction: '',
  description: '',
  status: '',
  avatar_url: '',
};
export const bodItemSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setItem: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setDefaultValue: (_, action) => {
      if (!action?.payload?.id) return initialState;
      return { ...action.payload };
    },
    resetData: () => {
      return initialState;
    },
  },
});
export const { setItem, setDefaultValue, resetData } = bodItemSlice.actions;

export default bodItemSlice.reducer;
