import axios from 'axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.response.use(
  res => res,
  err => {
    return Promise.reject(err);
  },
);

const defaultOptions = {
  baseURL,
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
  },
};

function setHeader(headers) {
  defaultOptions.headers = {
    ...defaultOptions.headers,
    ...headers,
  };
}

function handleRequest(options) {
  return axios.request({ ...defaultOptions, ...options });
}

// eslint-disable-next-line class-methods-use-this
function handleError(error) {
  // you can customize the error handler here based on your api error response
  if (error.response && error.response.data) {
    throw new Error(error.response.data.message);
  }

  throw new Error('Unknown Error');
}

export default {
  handleRequest,
  handleError,
  setHeader,
};
