import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: '',
  description: '',
  title_jp: '',
  description_jp: '',
  banner_url: '',
  our_partner: [],
  date: '',
  status: 'active',
  our_customer: [{ category_id: '', image: [] }],
};
export const introductionItemSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setItem: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setDefaultValue: (_, action) => {
      return { ...action.payload };
    },
    resetData: () => {
      return initialState;
    },
  },
});
export const { setItem, setDefaultValue, resetData } = introductionItemSlice.actions;

export default introductionItemSlice.reducer;
