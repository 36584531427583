import { createSlice } from '@reduxjs/toolkit';

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    show: 'responsive',
  },
  reducers: {
    setShow: (state, action) => {
      state.show = action.payload;
    },
  },
});
export const { setShow } = sidebarSlice.actions;

export default sidebarSlice.reducer;
