import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  services_category_id: '',
  name: '',
  name_jp: '',
  image: '',
  description: '',
  description_jp: '',
  banner: '',
  our_partner: [],
  happy_clients: '',
  expert_people: '',
  portfolios: '',
  status: '',
  project_done: '',
  process: 1,
  iot_projects: [],
};
export const serviceItemSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setItem: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setDefaultValue: (_, action) => {
      if (!action?.payload?.id) return initialState;
      return { ...action.payload };
    },
    resetData: () => {
      return initialState;
    },
  },
});
export const { setItem, setDefaultValue, resetData } = serviceItemSlice.actions;

export default serviceItemSlice.reducer;
