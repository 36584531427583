import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  name: '',
  description: '',
  description_jp: '',
  name_jp: '',
  category_id: '',
  content: '',
  salary: '',
  max_salary: '',
  min_salary: '',
  salary_type: 'range',
  salary_unit: 'usd',
  status: 0,
  is_status: 0,
  time: '',
  block_id: '',
  end_date: '',
  is_new: 0,
  is_hot: 0,
  is_show_homepage: 0,
  amount: '',
  image: '',
};
export const jobItemSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setItem: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setDefaultValue: (_, action) => {
      if (!action?.payload?.id) return initialState;
      return { ...action.payload };
    },
    resetData: () => {
      return initialState;
    },
  },
});
export const { setItem, setDefaultValue, resetData } = jobItemSlice.actions;

export default jobItemSlice.reducer;
