import { createSlice } from '@reduxjs/toolkit';

export const MessageSlice = createSlice({
  name: 'message',
  initialState: {
    show: false,
    color: 'danger',
    timeOut: 3000,
    header: 'Thông báo',
    body: '',
  },
  reducers: {
    setValue: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
export const { setValue } = MessageSlice.actions;

export default MessageSlice.reducer;
